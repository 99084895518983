.OrderMainContainer {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%; /* Ensure the element takes full available width up to the max-width */
  max-width: 500px;
  min-width: 150px;
  margin: 0 auto; /* Centers horizontally */
}

/* Start first container */
.OrderCon1 {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  box-sizing: border-box;
  overflow-y: scroll;
}
.OrderCon2 {
  display: flex;
  padding: 6px 12px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--app-orange, #ff7043);
  background: var(--app-white, #fff);
}
.OrderDiscountDisplayText1 {
  align-self: stretch;
  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderDiscountDisplayText2 {
  align-self: stretch;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OrderDiscountDisplayText3 {
  align-self: stretch;
  color: var(--app-gold-yellow, #f4c542);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderCon3 {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: var(--app-white, #fff);
}
.OrderCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.OrderCon5 {
  display: flex;
  padding: 6px 0px;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid #000;
}
.OrderTextHeader1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OrderCon6 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.OrderCon6Text {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OrderCon7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.OrderCon8 {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-bottom: 0.5px solid var(--app-gray-medium, #6d6d6d);
  background: var(--app-white, #fff);
}
.OrderCon9 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}
.OrderText1 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OrderCon10 {
  display: flex;
  padding: 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  border-radius: 6px;
  background: var(--app-white, #fff);
}
.OrderCon11 {
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--MAI-linear, #03c11f);
  background: var(--app-white, #fff);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.OrderTextHeader2 {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OrderCon12 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.OrderCon13 {
  display: flex;
  width: 150px;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
}
.OrderCon14 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  width: 138px;
  background: #fffffff6;
  /* border-radius: 3px; */
  border-bottom: solid 0.5px #000;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5) inset;

  color: var(--app-orange, #ff7043);
  text-align: center;
  text-shadow: 0.5px 0.5px 0.2px rgba(0, 0, 0, 0.25);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 4px;
}
.OrderCon15 {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #f5f802;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
.DeCashbackAplicado {
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}
.OrderCon16 {
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);

  color: var(--app-orange, #ff7043);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
}

.OrderCuponAplyButtonNoneAct {
  opacity: 0.3;
}

.OrderCuponImportantMessage {
  color: var(--Danger-color-red, #f00);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OrderCon17 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.OrderCon19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.OrderCon20 {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  background: var(--app-white, #fff);
}
.OrderCon21 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}
.OrderCon22 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-top: 1px solid var(--app-charcoal-dark, #2b2b2b);
}
.OrderText2 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderConButtonNext {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 6px;
  background: var(--app-charcoal-dark, #2b2b2b);

  color: var(--app-gold-yellow, #f4c542);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
}

.OrderCon23 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--app-white, #fff);
}
.OrderCon25 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 0.5px solid #2b2b2b;
}
.OrderCon26 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
.OrderCon27 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.Frame418 {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #000;
}
.OrderCon28 {
  display: flex;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.OrderCon29 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
.OrderButtonNext {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.OrderButtonBack {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

/* Start b */
.OrderConB1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid var(--app-gray-soft, #e0e0e0);
  background: var(--app-white, #fff);
}
.OrderConB2 {
  display: flex;
  padding: 6px 12px;
  align-items: flex-start;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
.OrderConB3 {
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px #e0e0e0 inset;
}
.OrderConB4 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.OrderConB5 {
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  overflow: hidden;
  border-right: 0.5px solid var(--app-gray-soft, #e0e0e0);
  background: var(--app-white, #fff);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.12);
}
.OrderConB6 {
  display: flex;
  padding: 0px 3px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--app-gray-soft, #e0e0e0);
}
.OrderConB7 {
  display: flex;
  padding: 6px 6px 0px 6px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
}
.OrderConB8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.OrderConB9 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.OrderConB10 {
  overflow: hidden;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--app-gray-soft, #e0e0e0);
}
.OrderConB10Img {
  height: 100%;
}
.OrderConB11 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.OrderText1B {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderConB12 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.OrderConB13 {
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid var(--app-link-color, #00349b);
}
.OrderText1D {
  color: var(--app-link-color, #00349b);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderConB14 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.OrderConB15 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.OrderConB16 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.OrderText1C {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderConB17B {
  display: flex;
  align-items: center;
  gap: 12px;
}
.OrderConB17 {
  display: flex;
  align-items: center;
  gap: 6px;
  border-bottom: 0.5px solid var(--app-charcoal-dark, #2b2b2b);
}
.OrderConB18 {
  display: flex;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.OrderConB19 {
  width: 50px;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.OrderConB20 {
  display: flex;
  padding: 6px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 0.5px solid var(--app-gray-medium, #6d6d6d);
}
.OrderConB21 {
  display: flex;
  padding: 0px 12px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.OrderConB22 {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: var(--app-gold-yellow, #f4c542);

  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Start message */
.OrderConCashBackMessageCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  border-radius: 12px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 12px 0px rgba(0, 0, 0, 0.5) inset;
}
.OrderConCashBackMessageCon2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.OrderConCashBackMessageCon3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}
.OrderConCashBackMessageText1 {
  color: var(--app-white, #fff);
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OrderConCashBackMessageText2 {
  color: var(--app-white, #fff);
  text-align: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.OrderConCashBackMessageText3 {
  align-self: stretch;

  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Start points input */
.OrderCon1DarkTransparentBackground {
  background: rgba(0, 0, 0, 0.3);
}
.OrderConInputPointsCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  box-sizing: border-box;

  border-radius: 12px;
  background: var(--app-white, #fff);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.OrderConInputPointsCon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.OrderTextHeader1 {
  align-self: stretch;
  text-align: center;
}
.OrderTextHeader1F {
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OrderConInputPointsCon3 {
  display: flex;
  padding: 2px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-bottom: 0.5px solid #000;
  background: #fff;
}
.OrderTextHeader1G {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

/* Subscription */

.OrderConSubB1 {
  display: flex;
  padding: 0px 12px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}
.OrderConSubB2 {
  display: flex;
  padding: 3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.OrderConSubB3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.OrderConSubB3B {
  display: flex;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}
.OrderText4 {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.OrderText4B {
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OrderConSubB4 {
  display: flex;
  padding: 2px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.OrderText3 {
  color: var(--Success-color-green, #33b546);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
